import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import '../fragments';

import { localize } from '../utils';
import { DEFAULT_LANG } from '../constants';
import SEO from '../components/seo';
import Layout from '../components/Layout';

const GalleryPage = ({ data, lang }) => {
  const langs = [lang];
  if (lang !== DEFAULT_LANG) {
    langs.push(DEFAULT_LANG);
  }

  const site = localize(data.sanitySite, langs);

  return (
    <Layout>
      <SEO langs={langs} />
      <div className="gallery_index">
        <h3>Galeria</h3>
        {site.galleries.map(gallery => (
          <p key={gallery.slug.current}>
            <Link to={'/galeria/' + gallery.slug.current + '/'}>{gallery.title}</Link>
          </p>
        ))}
      </div>
    </Layout>
  );
};

GalleryPage.defaultProps = {
  lang: DEFAULT_LANG,
};

GalleryPage.propTypes = {
  lang: PropTypes.string,
};

export const query = graphql`
  query {
    sanitySite {
      galleries {
        title
        slug {
          current
        }
      }
    }
  }
`;

export default GalleryPage;
